import React from "react";
import pageImage from "images/HomepageLogo.png";
import styled from "styled-components";
import { Col, Row } from "reactstrap";
import { Layout, PageContainer, SEO } from "components";
import { Link } from "gatsby";

const LegalPage = ({ location }) => {
  const title = "Legal";
  const description = "Terms of Service";
  return (
    <Layout clearNav={false} location={location}>
      <SEO
        image={pageImage}
        title={title}
        uri={location.pathname}
        description={description}
      />
      <LegalPageContainer>
        <Row>
          <Col xs={12} md={8} lg={6}>
            <Section>
              <h3>Legal</h3>
              <Link className="block" to="/ecl-terms-of-service">
                READ ECL TERMS OF SERVICE AGREEMENT
              </Link>
              <a
                className="block"
                href="/CCPA_Privacy_Policy_for_California_Residents.pdf"
                download
              >
                READ ECL PRIVACY POLICY FOR CALIFORNIA RESIDENTS
              </a>
            </Section>
            <Section>
              <h3>Third Party Terms of Service</h3>
              <p>
                Emerald Cloud Lab utilizes services provided by Wolfram Research
                and Amazon and, therefore, any use of the ECL must comply with
                their respective policies:
              </p>
              <a
                className="block"
                href="https://aws.amazon.com/agreement"
                rel="noreferrer noopener nofollow"
                target="_blank"
              >
                READ AWS AMAZON AGREEMENT
              </a>
              <a
                className="block"
                href="http://www.wolfram.com/legal/agreements/wolfram-mathematica.html"
                rel="noreferrer noopener nofollow"
                target="_blank"
              >
                READ WOLFRAM MATHEMATICA AGREEMENT
              </a>
            </Section>
          </Col>
        </Row>
      </LegalPageContainer>
    </Layout>
  );
};

export default LegalPage;

const LegalPageContainer = styled(PageContainer)`
  margin-top: 5rem;
  min-height: 60vh;
`;

const Section = styled.div`
  margin-top: 4.5rem;
`;
